.whole_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    .category {
        .category-cover {
            width: 100%;
            height: 151px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative !important;
            }
        }
        .top-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #ffffff;
            box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
            padding: 16px;
            .table-num {
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: $primaryColor;
            }
            .table-btn {
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: $primaryColor;
                background: #f1f1f1;
                border-radius: 87px;
                padding: 6px 12px;
            }
        }
        .delivery_content {
            width: 100%;
            height: 151px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50px;
            .delivery_category {
                top: 20%;
                left: 38%;
                background-color: #ffffff;
                padding: 8px 12px;
                border-radius: 75px;
                display: flex;
                justify-content: center;
                align-items: center;

                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: $primaryColor;
                .arrowdown {
                    margin-top: 3px;
                    margin-left: 8px;
                }
            }
        }
        .category_container {
            padding: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
        .food-items {
            position: relative;
            @media only screen and (min-width: 768px) {
                width: calc(50% - 8px);
            }
            @media only screen and (min-width: 1360px) {
                width: calc(33.33% - 11px);
            }
            .food-itemsimg {
                width: 100%;
                height: 36vw;
                position: relative;
                @media only screen and (min-width: 768px) {
                    height: 18.5vw;
                    max-height: 140px;
                }
                img {
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 1.6;
                }
            }
            .order_status {
                position: absolute;
                width: 100%;
                top: calc(50% - 15px);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                line-height: 140%;
                div {
                    color: #fff;
                    opacity: 1;
                    z-index: 1;
                }
            }
            .item_name {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                /* identical to box height */
                text-align: center;
                color: #ffffff;
                position: absolute;
                bottom: 10px;
                left: 20px;
                margin: 0px;
                text-transform: capitalize;
                z-index: 1;
            }
            .item_nameinactive {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }
        .food-itemsimg:after {
            content: "";
            position: absolute;
            z-index: 1;
            border-radius: 0.25rem;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: inline-block;
            // background: -moz-linear-gradient(
            //     0deg,
            //     rgba(33, 33, 33, 0.85),
            //     rgba(33, 33, 33, 0.6)
            // );
            background-color: rgba(0, 0, 0, 0.5);
            /* FF3.6+ */
            background: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(0%, rgba(33, 33, 33, 0.6)),
                color-stop(100%, rgba(33, 33, 33, 0.85))
            );
            /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(
                top,
                rgba(33, 33, 33, 0.85) 0%,
                rgba(33, 33, 33, 0.4) 100%
            );
            /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(
                top,
                rgba(33, 33, 33, 0.68) 0%,
                rgba(33, 33, 33, 0.4) 100%
            );
            /* Opera 11.10+ */
            background: -ms-linear-gradient(
                top,
                rgba(33, 33, 33, 0.68) 0%,
                rgba(33, 33, 33, 0.4) 100%
            );
            /* IE10+ */
            background: linear-gradient(
                to bottom,
                rgba(33, 33, 33, 0.68) 0%,
                rgba(33, 33, 33, 0.4) 100%
            );
            /* W3C */
            // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='$primaryColor00', GradientType=0);
            /* IE6-9 */
        }
    }
}

.ant-popover {
    width: 300px !important;
    left: 10px !important;
    .ant-popover-title {
        padding: 10px;
        text-align: center;
    }
}

.ant-popover-inner-content {
    display: flex;
    justify-content: space-between;
}

.venue-operating-hour {
    padding: 16px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $primaryColor;
    cursor: pointer;
    background-color: $primaryColor;
    color: #ffffff;
    width: 140px;
    margin: 16px 16px 0px 16px;
    border-radius: 8px;
}

.bookinghours-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    .bookinghours {
        width: 48%;
    }

    .bookinghours_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: $primaryColor;
    }
    .time {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $primaryColor;
    }
}

.bookinghours-wrapper .bookinghours:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-left: 1px solid #e9e9e9;
}
