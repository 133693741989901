*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    box-sizing: inherit;
    scroll-behavior: smooth;
}

body {
    font-family: "Inter";
    font-style: normal;
}

// h3 {
//     font-family: 'Inter';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 26px;
//     line-height: 124.5%;
//     letter-spacing: -0.01em;
//     color: #171717;
// }
.landing_headingII {
    font-family: $hellix;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    color: #171717;
    margin-bottom: 16px !important;
    @media only screen and (min-width: 1024px) {
        margin-bottom: 24px !important;
        font-size: 42px;
        line-height: 54px;
    }
}

.landing_paragraph {
    font-family: $hellix;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #4a4a4a;
    // margin-bottom: 32px;
}

.heading-III {
    font-family: $hellix;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 124.5%;
    letter-spacing: -0.01em;
    color: #171717;
    margin-bottom: 8px;
}

.imageicon {
    display: none;
    width: 120px;
}

.padding {
    padding: 60px 16px;
}
.link_ctn:hover {
    color: $primaryColor !important;
}
.container_wrap {
    max-width: 1440px;
    margin: 0px auto;
}

@media only screen and (min-width: 860px) {
    .padding {
        padding: 60px 40px;
    }
    .imageicon {
        display: block;
    }
}

@media only screen and (min-width: 1024px) {
    .padding {
        padding: 60px 80px;
    }
}

@media only screen and (min-width: 1360px) {
    .padding {
        padding: 60px 100px;
    }
}
.signup_createbtn {
    button {
        font-family: $fontfam-1;
    }
}
