.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    // margin: 40px 0px 0px 0px;
    background-color: #ffffff;
    @media only screen and (min-width: 560px) {
        padding-bottom: 20px;
    }
    .powered_by {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        p {
            @include small-font;
            text-align: center;
            color: #211e27;
            margin: 0px 8px 0px 0px;
            padding: 0px;
        }
    }
    .welcomepage__desc {
        font-weight: 400;
        font-size: 9px;
        font-family: $font-fam;
        line-height: 11px;
        text-align: center;
        color: #646268;
    }
    img {
        width: 70px;
        object-fit: cover;
    }
    .learnmore {
        @include small-font;
        text-align: center;
        text-decoration-line: underline;
        color: #211e27;
    }
}
