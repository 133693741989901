.loading-spinner {
    width: 36px;
    height: 36px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #212121;
    border-right: 3px solid #212121;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position: absolute;
    left: calc(50% - 34px);
    top: 60px;
}
.loader-nspinner {
    width: 24px;
    height: 24px;
    border: 2px solid #f3f3f3;
    border-top: 3px solid #212121;
    border-right: 3px solid #212121;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.listing-loader {
    top: 112px;
}

.order-loader {
    top: 60px;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
