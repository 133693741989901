.welcome-page {
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    .cover__image {
        width: 100%;
        min-height: 300px;
        height: 35vh;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 94px;
        width: 140px;
        height: 140px;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .cover__image:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background: -moz-linear-gradient(
            0deg,
            rgba(33, 33, 33, 0),
            rgba(33, 33, 33, 0)
        );
        /* FF3.6+ */
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, rgba(33, 33, 33, 0)),
            color-stop(100%, rgba(33, 33, 33, 0))
        );
        /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(
            top,
            rgba(33, 33, 33, 0) 0%,
            rgba(33, 33, 33, 0) 100%
        );
        /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(
            top,
            rgba(33, 33, 33, 0) 0%,
            rgba(33, 33, 33, 0) 100%
        );
        /* Opera 11.10+ */
        background: -ms-linear-gradient(
            top,
            rgba(33, 33, 33, 0) 0%,
            rgba(33, 33, 33, 0) 100%
        );
        /* IE10+ */
        background: linear-gradient(
            to bottom,
            rgba(33, 33, 33, 0) 0%,
            rgba(33, 33, 33, 0) 100%
        );
        /* W3C */
        /* IE6-9 */
    }
    .home__content {
        width: 100%;
        background-color: #ffffff;
        padding: 20px 20px 0 20px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 270px;
        min-height: 300px;
        height: calc(100% - 270px);
        .button_container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .content-box {
            .top-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;
                .table-num {
                    font-family: $font-fam;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    color: #000000;
                }
                .table-btn {
                    @include small-font;
                    text-align: center;
                    color: #211e27;
                    background: #f1f1f1;
                    border-radius: 87px;
                    padding: 6px 12px;
                    cursor: pointer;
                }
            }
            .content-btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 16px;
                margin-bottom: 16px;
                a {
                    width: 100%;
                }
                img {
                    width: 42px;
                    height: 12px;
                    color: #000000;
                    object-fit: cover;
                    margin-top: 4px;
                }
            }
        }
    }
}
.rprofile_socialicons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    margin-top: 4px;
}
.rprofile_socialicons img {
    cursor: pointer;
}
.socialicon-svg {
    width: 22px;
    height: 22px;
}
