.order-wrapper {
    padding: 50px 0 0px 0;
}

.order-wrapper h2 {
    display: flex;
    justify-content: flex-start;
    padding: 14px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $primaryColor;
    margin: 0px;
}

.info-content {
    display: flex;
    background: #f0c8c8;
    justify-content: space-between;
    width: 100%;
    padding: 14px;
    .table_info {
        font-weight: 400;
        font-size: 11.9048px;
        line-height: 14px;
        color: #cc1818;
    }
    :nth-child(2) {
        text-decoration: underline;
    }
}

.table__left {
    display: flex;
    flex-direction: column;
}

.table__left h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #646268;
    margin: 0px !important;
    text-transform: capitalize;
}

.tabletopping__wrapper .table_topping {
    display: flex;
    flex-direction: row;
}

.table__left .table_topping span {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #646268;
    text-transform: capitalize;
    margin-top: 3px;
}

.table_topping span:first-child {
    margin-right: 4px;
}

.item__total-price {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #646268;
    margin-top: 16px;
}

.table-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 14px;
}

.additem_link {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #646268;
}

.table-button button {
    padding: 8px;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 87px;
    border: none;
}

.kitchen_note {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    padding: 14px;
    background: #ffffff;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    margin-top: 11px;
    // position: absolute;
    // width: 100%;
    // bottom: 176px;
    .kitchen_noteleft {
        display: flex;
        align-items: center;

        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #646268;
        .filltag {
            margin-right: 8px;
            font-size: 16px;
        }
    }
    .table__right-arrow {
        font-size: 20px;
        line-height: 15px;
        color: #646268;
    }
    .notebox {
        margin-top: 24px;
        width: 100%;
        textarea {
            border: 1px solid #f9f9f9;
            width: 100%;
            height: 60px;
            border-radius: 8px;
            padding: 8px;
            outline: none;
        }
        textarea::placeholder {
            font-size: 14px;
            color: #d9d9d9;
            font-weight: 400;
        }
    }
}

.pricing {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    .price {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #646268;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }
    .total {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #211e27;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.table-wrapper {
    padding-bottom: 200px;
    .table-container {
        display: flex;
        justify-content: space-between;
        padding: 14px;
    }
}

.table__right .inc-dec {
    border: 0.723214px solid #646268;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
}

.add-icon {
    font-size: 16px;
}

.inc-dec__num {
    margin: 0px 12px;
}

.table__right {
    display: flex;
    .inc-dec__num {
        height: auto;

        font-weight: 400;
        font-size: 15.9107px;
        line-height: 19px;
        text-align: center;
        color: $primaryColor;
    }
}

// .continue-btn {
//     button {
//         padding: 16px;
//         border: none;
//         width: 100%;
//         border-radius: 8px;
//         background: #f1f1f1;
//         font-family: "Inter";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 22px;
//         line-height: 27px;
//         text-align: center;
//         color: #ffffff;
//     }
//     .continuebtnactive {
//         background-color: $primaryColor;
//     }
//
// }
// .continuebtn {
//     background-color: #f1f1f1;
// }
.table__drawer {
    .ant-form-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .name_inputfield.active {
        // margin-bottom: 12px;
        margin-top: -8px;
    }
    .ant-form-item-label {
        text-align: start;
        @media only screen and (min-width: 768px) {
            margin-bottom: 8px;
        }
    }
    .ant-form-item-control-input {
        @media only screen and (min-width: 768px) {
            width: 100% !important;
        }
    }
    .ant-input-number,
    .ant-input-number-input-wrap {
        width: 100% !important;
        border-radius: 8px;
    }
    .ant-input-number-input-wrap input {
        width: 100% !important;
        padding: 8px;
        border-radius: 8px;
        height: 46px;
    }
    .ant-drawer-content-wrapper {
        height: 278px !important;
        .ant-drawer-header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px !important;
            font-weight: 700;
            font-size: 23px;
            line-height: 28px;
            color: $primaryColor;
            .ant-drawer-title {
                font-size: 18px;
                font-weight: 700;
                line-height: 1.3;
                @media only screen and (min-width: 768px) {
                    text-align: center;
                }
            }
        }
        .drawer_validationmsg {
            position: absolute;
            color: #ff0000b2;
            margin-bottom: 4px;
        }
        .anticon-close {
            color: $primaryColor;
            font-size: 16px;
        }
        .ant-btn {
            height: 60px;
            width: 100%;
            border-radius: 8px;
            padding: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primaryColor;
            color: #ffffff;
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
            text-align: center;
            border: none;
        }
        .disabledBtn {
            background-color: #cccccc;
        }
    }
    .ant-drawer-body {
        padding-bottom: 0px !important;
        padding-top: 16px;
    }
    .table__form {
        .ant-input {
            padding: 16px;
            border-radius: 8px;
            border: 1px solid $primaryColor;
            // position: relative;
        }
        .ant-form-item-label {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $primaryColor;
            .ant-form-item-required {
                // position: absolute;
                // top: 0px;
                // z-index: 99999;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: #211e27;
            }
            .ant-form-item-required::before {
                display: none;
            }
        }
    }
    select {
        width: 180px;
        border: none;
        padding: 8px;
        font-size: 16px;
        line-height: 1;
        margin-top: 4px;
        width: 100%;
        border: 1px solid #e9e9e9;
        height: 46px;
        border-radius: 8px;
        color: $primaryColor;
        outline: none;
        padding: 8px;
        font-weight: 400;
        font-size: 14px;
        outline: none;
    }
}

.ordertable_footer {
    padding: 20px;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    bottom: 0px;
}

.remove_modal {
    border-radius: 8px !important;
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-body {
        padding: 0px;
        p {
            padding: 24px 24px 8px 24px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $primaryColor;
        }
    }
    .removemodal_footer {
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 1px solid #f1f1f1;
        padding: 16px 0;
    }
    .removemodal_footer button {
        padding: 12px;
        width: 120px;
        border: none;
        border-radius: 8px;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $primaryColor;
    }
    .removemodal_footer button:nth-child(2) {
        background-color: #f43f6f;
        color: #ffffff;
    }
    .removemodal_footer button:nth-child(1) {
        background-color: #ffffff;
        border: 1px solid black;
        color: $primaryColor;
        margin-right: 16px;
    }
}

.orderModal {
    .ant-modal-header {
        border-top: 1px solid #f1f1f1;
    }
    .ant-modal-footer {
        display: none;
    }
    .ant-modal-body {
        padding: 0px;
        p {
            padding: 24px 16px 8px 24px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $primaryColor;
        }
    }
    .removemodal_footer {
        border-top: 1px solid #f1f1f1;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 0;
        padding-bottom: 16px;
    }
    .removemodal_footer button {
        padding: 12px;
        width: 120px;
        border: none;
        border-radius: 8px;

        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $primaryColor;
    }
    .removemodal_footer button:nth-child(2) {
        background-color: #f43f6f;
        color: #ffffff;
    }
    .order_ok {
        color: #ffffff;
    }
    .removemodal_footer button:nth-child(1) {
        background-color: #ffffff;
        border: 1px solid black;
        color: $primaryColor;
        margin-right: 16px;
    }
}
