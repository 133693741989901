@font-face {
    font-family: "TT Norms Pro";
    src: url(../../fonts/TTRegular.otf);
}
@font-face {
    font-family: tt-pro;
    src: url(../../fonts/TTNormsProBlack.otf);
}
@font-face {
    font-family: "HelveticaNeue";
    src: url(../../fonts/HelveticaNeue/HelveticaNeue.ttf);
}
@font-face {
    font-family: "HelveticaNeue-medium";
    src: url(../../fonts/HelveticaNeue/HelveticaNeue-Medium.otf);
}
@font-face {
    font-family: "HelveticaNeue-regular";
    src: url(../../fonts/HelveticaNeue/HelveticaNeue-Regular.ttf);
}
@font-face {
    font-family: "HelveticaNeue-bold";
    src: url(../../fonts/HelveticaNeue/HelveticaNeue-CondensedBold.otf);
}
