.whole_listing {
    padding-bottom: 100px;

    .listing-menu {
        min-height: calc(100vh - 204px);
        .top-bar {
            display: flex;
            border-bottom: 1px solid #f1f1f1;
            padding: 9px 14px;
            justify-content: space-between;
            position: fixed;
            width: 100%;
            top: 50px;
            background-color: $secondaryColor;
            z-index: 1;
            .topbar_left {
                display: flex;
            }
            .topbar_right {
                background-color: $primaryColor;
                color: $secondaryColor;
                padding: 4px 12px;
                border-radius: 185px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: capitalize;
                .viewmenu_arrow {
                    color: $secondaryColor;
                    font-size: 12px;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 3px;
                    margin-top: 2px;
                }
            }
            .filter-icon,
            .search-icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #f1f1f1;
                margin-right: 8px;
                img {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                }
                .bisearch {
                    color: #211e27;
                    font-size: 16px;
                }
            }
            .filter-icon,
            .search-icon:hover {
                background-color: #948f8f34;
                transition: all 0.6s ease-in;
            }
        }
        .search-bar {
            position: fixed;
            display: flex;
            justify-content: center;
            top: 50px;
            left: -100%;
            z-index: 10000;
            background-color: $secondaryColor;
            border-radius: 8px 8px;
            height: 50px;
            padding: 8px 14px 8px 14px;
            width: 100%;
            transition: all 0.5s ease-out;
            .input-search {
                width: calc(100% - 98px);
                position: relative;
                input {
                    border: 1px solid #f1f1f1;
                    border-radius: 185px;
                    padding: 5px 18px;
                    padding-left: 30px;
                    width: 100%;
                    outline: none;
                }
                .bisearch_inner-icon {
                    position: absolute;
                    left: 10px;
                    top: 11px;
                    color: #211e27;
                }
            }
            .cancel-btn {
                width: 86px;
                border: 1px solid #f1f1f1;
                border-radius: 185px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #211e27;
                padding: 8px 18px;
            }
        }
        .search-bar.active {
            display: flex;
            align-items: center;
            justify-content: space-between;
            left: 0;
            top: 50px;
            height: 50px;
            z-index: 1000;
            width: 100%;
        }
        .menu_wrapper {
            padding: 76px 16px 16px 16px;
            h2 {
                text-transform: capitalize;
                font-weight: 700;
                font-size: 23px;
                line-height: 28px;
                color: $primaryColor;
                margin-bottom: 8px;
            }
            .menu_innerbox {
                display: flex;
                flex-direction: column;
                // gap: 16px;
                a {
                    width: 100%;
                    @media only screen and (min-width: 768px) {
                        width: calc(50% - 8px);
                    }
                    @media only screen and (min-width: 1360px) {
                        width: calc(33.33% - 12px);
                    }

                    .menu_items {
                        width: 100%;
                        box-shadow: none !important;

                        border-radius: 8px;
                        border: 1px solid #f1f1f1;
                        height: 36vw !important;
                        @media only screen and (min-width: 768px) {
                            // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
                            border-radius: 0.25rem;
                            height: 18.5vw;
                            // max-height: 140px !important;
                        }
                    }
                }
                .sold_out,
                .soldinactive {
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(
                        to bottom,
                        rgba(33, 33, 33, 0.68) 0%,
                        rgba(33, 33, 33, 0.4) 100%
                    );
                    border-top-left-radius: 15px;
                    border-bottom-left-radius: 15px;
                    position: absolute;
                }
                .soldinactive {
                    border-radius: 8px;
                    @media only screen and (min-width: 768px) {
                        border-radius: 8px;
                    }
                }
                .sold_out {
                    width: 42.6%;
                }
                .sold_span,
                .sold_spancenter {
                    color: $secondaryColor;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 16px;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    top: 0px;
                    // left: calc(50% - 44px);
                    width: 100%;
                    height: 100%;
                }
                .sold_span {
                    position: absolute;
                    // left: 40px;
                }
                h2 {
                    width: 100%;
                    overflow-wrap: break-word;
                    font-weight: 700;
                    font-size: 23px;
                    line-height: 28px;
                    color: $primaryColor;
                    margin-bottom: 24px;
                    text-transform: capitalize;
                }
                .menuitems_wrap {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 16px;
                }
                .menu_itemsactive,
                .menu_items {
                    display: flex;
                    border-radius: 0.25rem;
                    height: 36vw;
                    position: relative;
                    transition: all 0.1s ease-in-out;
                    box-shadow: (0 0 #0000, 0 0 #0000), (0 0 #0000, 0 0 #0000),
                        0px 0px 1px rgba(29, 29, 29, 0.2),
                        0px 0px 25px rgba(29, 29, 29, 0.03) !important;
                    &:hover {
                        transform: scale(1.05);
                        @media only screen and (min-width: 768px) {
                            transform: scale(1) !important;
                        }
                    }
                    @media only screen and (min-width: 768px) {
                        max-height: 192px;
                    }

                    .listingproduct_img {
                        width: 38.5vw;
                        // aspect-ratio: 1/1;
                        @media only screen and (min-width: 768px) {
                            width: 192px;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            border-top-left-radius: 0.25rem;
                            border-bottom-left-radius: 0.25rem;
                            object-fit: cover;
                        }
                    }
                    .listingproduct_none {
                        display: none;
                    }
                }
                .menu_inner_content,
                .menuinnercontent_opacity,
                .menu-wholecontent {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin: 12px;
                    width: 55%;
                    .menu_subcontentI {
                        width: 100%;
                        div {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 4px;
                        }
                        .menu_status {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $primaryColor;
                            width: 74px;
                            padding: 4px;
                            text-transform: uppercase;
                            border-radius: 32px;
                            color: $secondaryColor;

                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                        }
                        h4 {
                            @include medium-font;
                            font-family: $mediumfont-fam;
                            color: #211e27;
                            margin: 0px;
                            text-transform: capitalize;
                        }
                        p {
                            @include medium-font;
                            font-family: $font-fam;
                            color: #646268;
                            margin-bottom: 4px;
                        }
                        .aifire {
                            width: 18px;
                            height: 18px;
                            border-radius: 50%;
                            background-color: $primaryColor;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .aifire-icon {
                                font-size: 12px;
                                color: $secondaryColor;
                            }
                        }
                    }
                    .menu_subcontent {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .price {
                            @include medium-font;
                            font-family: $font-fam;
                            font-weight: 500;
                            color: $primaryColor;
                        }
                    }
                }
                .menuinnercontent_opacity {
                    opacity: 0.6 !important;
                }
                .menu-wholecontent {
                    width: 100% !important;
                }

                .cat_name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: $primaryColor;
                    text-transform: capitalize;
                    margin-bottom: 12px;
                }
                .catname_none {
                    display: none !important;
                }
            }
        }
    }
    .view_orderbtn {
        width: 100%;
        padding: 20px;
        background-color: $secondaryColor;
        position: fixed;
        bottom: 0px;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
        button {
            display: flex;
            justify-content: center;
            .viewordr_count_active {
                background-color: $secondaryColor;
                border-radius: 50%;
                width: 28px;
                height: 28px;
                padding: 12px;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
                color: $primaryColor;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                box-shadow: 6px 6px 11px rgba(0, 0, 0, 0.1);
            }
            .viewordr_count {
                display: none;
            }
        }
    }
}

.listing_drawer {
    .ant-drawer-content-wrapper {
        width: 320px !important;
        margin-top: 101px;
        box-shadow: none !important;
        // box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1) !important;
    }
    .ant-drawer-wrapper-body {
        position: relative;
        padding: 0px 16px;
    }
    .ant-drawer-header {
        border-bottom: 1px solid $secondaryColor !important;
        padding: 16px 8px;
    }
    .ant-drawer-mask {
        background-color: transparent !important;
    }
    .ant-drawer-body {
        padding: 0px;
        overflow: scroll;
    }
    .filter__drawer {
        .filter_content_wrapper {
            label {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        min-height: 91vh;
        height: auto;
        margin-bottom: 80px;
        overflow: scroll;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
        // height: 59vh;
        // overflow: scroll;
        // @media only screen and (min-width: 768px) {
        //     height: 67vh;
        // }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */

    .filter_applybtn {
        background-color: $secondaryColor;
        padding: 16px 0;
        bottom: 0px;
        position: fixed;
        transition: all 0.9s ease;
        button {
            width: 100%;
            height: 100%;
            min-width: 288px;
            background-color: $primaryColor;
            padding: 16px 0px;
            color: $secondaryColor;
            border: none;
            border-radius: 8px;
            text-align: center;
            font-weight: 600;
            font-size: 22px;
            line-height: 27px;
        }
    }
    .ant-drawer-mask {
        opacity: 0 !important;
    }

    .drawer_dietry_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid #f1f1f1;
        &:hover {
            background-color: #e9e9e9;
        }
        input {
            width: 20px !important;
            height: 20px !important;
            border-radius: 8px !important;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $primaryColor;
        }
        .ant-drawer-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: $primaryColor;
        }
    }
    .filter_content_wrapper div:last-child {
        border-bottom: 1px solid $secondaryColor !important;
    }
    .ant-drawer-close {
        display: none;
    }
    .ant-drawer-body::-webkit-scrollbar {
        display: none !important;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .ant-drawer-body {
        -ms-overflow-style: none !important;
        scrollbar-width: none !important ;
    }
}

.filter_content_wrapper.listing_drawer:last-child {
    border-bottom: none;
}

.viewmenu_drawer {
    .ant-drawer-content-wrapper {
        width: 280px !important;
        margin-top: 101px;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1) !important;
    }
    .ant-drawer-header {
        display: none;
    }
    .ant-drawer-mask {
        background-color: transparent !important;
    }
    .ant-drawer-body {
        padding-top: 16px;
    }
    .filter__drawer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 73vh;
        position: relative;
        @media only screen and (min-width: 768px) {
            height: 79vh;
        }
        @media only screen and (min-width: 1024px) {
            height: 86vh;
        }
        @media only screen and (min-width: 1440px) {
            height: 88.6vh;
        }
    }
    .menu_categoryitem {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $primaryColor;
        text-transform: capitalize;
        border-bottom: 1px solid #f1f1f1;
        padding: 16px 0px;
    }
}

.ant-notification-notice {
    width: auto;
    text-align: center;
    background-color: #beebfa !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    color: #fff !important;
    margin: auto;
    // position: absolute;
    padding: 8px !important;

    // right: 30% !important;

    // position: absolute !important;
    // top: -18px;
    // right: 30% !important;
    // width: auto;
    // max-width: 400px;
    // color: #fff !important;
    // box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
    // display: flex;
    // transition: all 350s ease-in-out;
    // font-size: 12px;
    // justify-content: center;
    // align-items: center;
    // margin: 0px;
    // padding-right: 0px;
    // min-width: 126px;
}

.ant-notification-notice-message {
    margin: 0px;
    padding: 0px !important;
    // width: auto;
}

.ant-notification-notice-close {
    display: none;
}
.cart_notification {
    max-width: 126px !important;
    .ant-notification-notice {
        position: absolute !important;
        top: 30px !important;
        right: 50% !important;
    }
}
// @media only screen and (min-width: 768px) {
//     .menu_items,
//     .menu_itemsactive {
//         border-radius: 0.25rem;
//         min-height: 18.5vw !important;
//         max-height: 140px !important;
//     }
// }
// @media only screen and (min-width: 768px) {
//     .menu_items,
//     .menu_itemsactive {
//         border-radius: 0.25rem;
//         min-height: 18.5vw !important;
//         max-height: 140px !important;
//     }
// }
