.navbar {
    background-color: $primaryColor !important;
    color: #ffffff;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    position: fixed;
    width: 100%;
    z-index: 2;
    .navmenu {
        margin-top: 6px;
        width: 30px;
        position: absolute;
        left: 16px;
        cursor: pointer;
    }
}

.left-arrow__icon {
    color: #ffffff;
    font-size: 24px;
}

.menu__sidebar {
    .side_link {
        color: $primaryColor;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .close-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        padding: 16px;
    }
    .ant-drawer-content-wrapper {
        width: 78% !important;
        @media only screen and (min-width: 425px) {
            width: 60% !important;
        }
        @media only screen and (min-width: 560px) {
            width: 50% !important;
        }
        @media only screen and (min-width: 768px) {
            width: 34% !important;
        }
    }
    .ant-drawer {
        position: relative;
    }
    .ant-drawer-mask {
        width: 100%;
    }
    .ant-drawer-header {
        padding: 16px !important;
        display: none;
        font-weight: 700;
        font-size: 23px;
        line-height: 28px;
        color: $primaryColor;
    }
    .ant-drawer-close {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 9;
    }
    .ant-drawer-header-title {
        .antiicon-close svg {
            color: #e21d1d !important;
        }
    }
    .ant-drawer-body {
        padding: 60px 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .drawer__menucontent {
        .menu-sidebar__img {
            width: 100px;
            margin-bottom: 64px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .sidebar__content {
        .content-II {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: #646268;
            margin-bottom: 18px;
        }
    }
    ul {
        padding: 0px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        li {
            list-style: none;

            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: $primaryColor;
        }
    }
    .menu__code {
        display: flex;
    }
    .menu__code span {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #646268;
    }
    .menu__code span::after {
        content: ".";
        font-size: 24px;
        margin: 0 4px;
    }
}

.nav-venuename {
    width: calc(100% - 30px);
    font-size: 18px;
    display: flex;
    justify-content: center;
    color: $secondaryColor;
    text-transform: capitalize;
    a {
        color: $secondaryColor;
    }
}

.dinein {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
}

.left-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    cursor: pointer;
}
